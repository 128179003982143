<template>
  <div class="flex flex-col gap-[3.12rem] pb-[1.88rem] lg:flex-row lg:pb-[3.12rem]">
    <div class="flex-1">
      <slot name="left" />
    </div>
    <div
      class="flex-0 mt-[1.88rem] min-w-full max-w-full lg:mt-0 lg:min-w-[21.875rem] lg:max-w-[21.875rem]"
    >
      <slot name="right" />
    </div>
  </div>
</template>
